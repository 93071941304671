import * as React from "react";

import { settingsBusinessLogos } from "../constants/carrouselConfig";
import { translations } from "../locales/business";
import BusinessSlider from "./layout/businessSlider";

export default function BusinessSection({ language }) {
  return (
    <section className="advantages container">
      <div className="advantage-text">
        <h2 className="title-section">
          {translations[language].title1}
          <span className="text-stand-out-purple">
            {translations[language].title2}
          </span>
        </h2>
        <p>{translations[language].text}</p>
      </div>
      <div className="div-businessLogo">
        <BusinessSlider config={settingsBusinessLogos} />
      </div>
    </section>
  );
}
