import React from "react";

export default function Tutorial() {
  return (
    <div
      className="container"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <iframe
        id="ytplayer"
        type="text/html"
        width="100%"
        height="100%"
        style={{ aspectRatio: "16/9" }}
        src="https://www.youtube.com/embed/jq-UDCvxmjs?si=dsvsnBsAiuABPo_6&autoplay=1&origin=https://kolibird.app/"
        frameBorder="0"
        title="tutorial"
      />
    </div>
  );
}
