export const translations = {
  es: {
    title1: "¿Quién ha confiado en ",
    title2: "Kolibird?",
    text: "Estos son algunos de los clientes corporativos con los que hemos trabajado hasta el momento",
    button: "Contactar",
  },
  en: {
    title1: "Who has trusted ",
    title2: "Kolibird?",
    text: "These are some of the corporate clients we have worked with so far",
    button: "Contact us",
  },
};
